@forward '@angular/material/prebuilt-themes/deeppurple-amber.css';

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  scroll-padding-top: 65px; // height of the fixed header

  body {
    background-color: #fff;
    min-height: 100%;
    height: 100%;
    margin: 0 0 80px;
    padding: 0;
    font-family: 'Helvetica Now Display', helvetica, arial, sans-serif;
    line-height: 1.25;
    color: black;
  }
}

.snackbar-bg-color {
  &--warning {
    .mat-mdc-snackbar-surface {
      border-left: 10px solid #ffa500 !important;
    }
  }
  &--negative {
    .mat-mdc-snackbar-surface {
      border-left: 10px solid #b93636 !important;
    }
  }
  &--positive {
    .mat-mdc-snackbar-surface {
      border-left: 10px solid #00c89e !important;
    }
  }
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 30px;
}

.container--small {
  max-width: 1200px;
}

.container--xs {
  max-width: 1024px;
}

h4,
.h4 {
  font-size: legacy-fontsize();
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 3px;
}

a {
  text-decoration: none;
  cursor: pointer;
}
